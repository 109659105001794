// Action Types
export const Types = {
    INACTIVITY_CREATE:'INACTIVITY_CREATE',
    INACTIVITY_CREATE_SUCESS: 'INACTIVITY_CREATE_SUCESS',
    INACTIVITY_CREATE_ERROR: 'INACTIVITY_CREATE_ERROR',
    INACTIVITY_UPDATE:'INACTIVITY_UPDATE',
    INACTIVITY_UPDATE_SUCESS: 'INACTIVITY_UPDATE_SUCESS',
    INACTIVITY_UPDATE_ERROR: 'INACTIVITY_UPDATE_ERROR',
    INACTIVITY_LIST:'INACTIVITY_LIST',
    INACTIVITY_WORD:'INACTIVITY_WORD',
    INACTIVITY_LIST_SUCESS: 'INACTIVITY_LIST_SUCESS',
    INACTIVITY_WORD_SUCESS: 'INACTIVITY_WORD_SUCESS',
    INACTIVITY_DELETE:'INACTIVITY_DELETE',
    INACTIVITY_DELETE_SUCESS: 'INACTIVITY_DELETE_SUCESS',
    INACTIVITY_DELETE_ERROR: 'INACTIVITY_DELETE_ERROR',
    INACTIVITY_UPDATE_TUTORIAL_STEP:'INACTIVITY_UPDATE_TUTORIAL_STEP',
    FIND_BY_ID:'FIND_BY_ID',
    FIND_BY_ID_SUCCESS:'FIND_BY_ID_SUCCESS',
    FIND_BY_ID_ERROR:'FIND_BY_ID_ERROR',
  }
  
  const INITIAL_STATE = {
    inactivity: {},
    word: null,
    inactivities: []  
  }  
  
  export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
      case Types.INACTIVITY_CREATE_SUCESS: {
        return {
          ...state,
          sucessMessage:"Configuração de inatividade registrada com sucesso"
        }
      }
      case Types.INACTIVITY_LIST_ERROR:
      case Types.INACTIVITY_CREATE_ERROR: {
        return {
          ...state,
          errorMessage:action.payload
        }
      }case Types.INACTIVITY_DELETE_SUCESS: {
        return {
          ...state,
          sucessMessage:"Configuração de inatividade removida com sucesso"
        }
      }case Types.INACTIVITY_DELETE_ERROR: {
        return {
          ...state,
          errorMessage:"Ocorreu um erro ao remover a configuração de inatividade"
        }
      }case Types.INACTIVITY_LIST_SUCESS: {
        return {
          ...state,
          inactivities:action.payload
        }
      }
      case Types.INACTIVITY_WORD_SUCESS: {
    
        return {
          ...state,
          word: action.payload
        }
      }
  
      case Types.FIND_BY_ID_SUCCESS: {
        return {
          ...state,
          inactivity:action.payload
        }
      }
      default:
        return state;
    } 
  }
  
  export const createInactivity = (inactivity) => {
    return {
        type: Types.INACTIVITY_CREATE,
        payload: inactivity
    };
  };
  
  export const updateInactivity = (inactivity) => {
    return {
        type: Types.INACTIVITY_UPDATE,
        payload: inactivity
    };
  };
  
  export const deleteInactivity = (id) => {
    return {
      type: Types.INACTIVITY_DELETE,
      payload: id
    };
  };
  
  export const inactivityList = (res) => {
    return {
      type: Types.INACTIVITY_LIST
    }
  }
  
  
  export const wordList = (res) => {
    return {
      type: Types.INACTIVITY_WORD
    }
  }
  
  
  export const updateTutorialSteps = (INACTIVITY) => {
    return {
      type: Types.INACTIVITY_UPDATE_TUTORIAL_STEP,
      payload: INACTIVITY
    };
  };
  
  export const findById = (id) => {
    return {
      type: Types.FIND_BY_ID,
      payload: id
    }
  }
  
  export const findByIdSuccess = (INACTIVITY) => {
    return {
      type: Types.FIND_BY_ID_SUCCESS,
      payload: INACTIVITY
    }
  }
  
  export const toggleModal = (visibility, selectedConfig = null) => ({
    type: 'TOGGLE_MODAL',
    payload: { visibility, selectedConfig }
  });
  
import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import {
  getMetrics,
  getInProgress,
  getAttendantMetrics,
  getIsAwaiting,
  getQueueMetrics
} from "../../services/Ticket";
import {
  Types,
  getAttendantMetricsSuccess,
  getInProgressSuccess,
  getIsAwaitingSuccess,
  getMetricsSuccess,
  getQueueMetricsSuccess,
  setLoadingMetrics,
  setLoadingReports,
  shouldReloadQueueMetrics
} from "../reducers/Ticket"
import { errorMessage } from "../actions/FeedbackMessage"

const getTicketStore = state => state.ticket;
const getQueueStore = state => state.queue;

export function* getMetricsSaga() {
    yield takeLatest(Types.GET_METRICS, function* ({ payload: filter }) {
        try {
            yield put(setLoadingMetrics(true))
            const metrics = yield call(getMetrics, filter)
            yield put(getMetricsSuccess(metrics))
        } catch(error) {
            yield put(errorMessage(error))
        } finally {
            yield put(setLoadingMetrics(false))
        }
    })
}

export function* getQueueMetricsSaga() {
    yield takeLatest(Types.GET_QUEUE_METRICS, function* ({ payload: filter }) {
        try {
            yield put(setLoadingReports(true))
            const metrics = yield call(getQueueMetrics, filter)
            yield put(getQueueMetricsSuccess(metrics))
        } catch(error) {
            yield put(errorMessage(error))
        } finally {
            yield put(setLoadingReports(false))
        }
    })
}

export function* getAttendantMetricsSaga() {
    yield takeLatest(Types.GET_ATTENDANT_METRICS, function* ({ payload: filter }) {
        try {
            yield put(setLoadingReports(true))
            const metrics = yield call(getAttendantMetrics, filter)
            yield put(getAttendantMetricsSuccess(metrics))
        } catch(error) {
            yield put(errorMessage(error))
        } finally {
            yield put(setLoadingReports(false))
        }
    })
}

export function* getInProgressSaga() {
    yield takeLatest(Types.GET_IN_PROGRESS, function* ({ payload: filter }) {
        try {
            yield put(setLoadingReports(true))
            const tickets = yield call(getInProgress, filter)
            yield put(getInProgressSuccess(tickets))
        } catch(error) {
            yield put(errorMessage(error))
        } finally {
            yield put(setLoadingReports(false))
        }
    })
}

export function* getIsAwaitingSaga() {
    yield takeLatest(Types.GET_IS_AWAITING, function* ({ payload: filter }) {
        try {
            yield put(setLoadingReports(true))
            const tickets = yield call(getIsAwaiting, filter)
            yield put(getIsAwaitingSuccess(tickets))
        } catch(error) {
            yield put(errorMessage(error))
        } finally {
            yield put(setLoadingReports(false))
        }
    })
}

//* Handles queue metrics after metrics are updated
function* handleQueueMetricsAfterNewInterventionRequest() {
    yield takeLatest(Types.RECEIVED_NEW_INTERVENTION_REQUEST, function* ({ payload: conversationTicket }) {
        const { queueMetrics } = yield select(getTicketStore)

        if(!queueMetrics) return

        const existis = queueMetrics.find(item => item.queueId === conversationTicket.queueId)

        if (!existis) {
          yield put(shouldReloadQueueMetrics())
          return
        }

        const newMetrics = queueMetrics.map(item => {
            if ( item.queueId !== conversationTicket.queueId ) {
                return item
            }

            return {
                ...item,
                awaiting: item.awaiting + 1,
                received: item.received + 1
            }
        })

        yield put(getQueueMetricsSuccess(newMetrics))
    })
}

function* handleQueueMetricsAfterStartOfANewIntervention() {
    yield takeLatest(Types.RECEIVED_START_OF_A_NEW_INTERVENTION, function* ({ payload: { conversationTicket } }) {
        const { queueMetrics } = yield select(getTicketStore)

        if(!queueMetrics) return

        const existis = queueMetrics.find(item => item.queueId === conversationTicket.queueId)

        if (!existis) {
            yield put(shouldReloadQueueMetrics())
            return
        }

        const newMetrics = queueMetrics.map(item => {
            if(item.queueId !== conversationTicket.queueId) {
                return item
            }

            const totalTimeToIntervene = item.totalTimeToIntervene + (conversationTicket.timeUntilIntervention || 0)

            return {
                ...item,
                awaiting: item.awaiting > 0 ? item.awaiting - 1 : item.awaiting,
                inProgress: (item.inProgress || 0) + 1,
                totalTimeToIntervene: totalTimeToIntervene,
                avgTimeToIntervene: Math.round(totalTimeToIntervene / item.received)
            }
        })

        yield put(getQueueMetricsSuccess(newMetrics))
    })
}

function* handleQueueMetricsAfterEndOfAnIntervention() {
    yield takeLatest(Types.RECEIVED_END_OF_AN_INTERVENTION, function* ({ payload: interventionTicket }) {
        const { queueMetrics } = yield select(getTicketStore)

        if(!queueMetrics) return

        const existis = queueMetrics.find(item => item.queueId === interventionTicket.queueId)

        if (!existis) {
            yield put(shouldReloadQueueMetrics())
            return
        }

        const newMetrics = queueMetrics.map(item => {
            if(item.queueId !== interventionTicket.queueId) {
                return item
            }

            const totalTimeToSolved = (item.totalTimeToSolved || 0) + (interventionTicket.timeToResolve || 0)
            const solved = (item.solved || 0) + 1

            return {
                ...item,
                inProgress: item.inProgress ? item.inProgress - 1 : item.inProgress,
                solved,
                totalTimeToSolved: totalTimeToSolved,
                avgTimeToSolve: Math.round(totalTimeToSolved / solved)
            }
        })

        yield put(getQueueMetricsSuccess(newMetrics))
    })
}

function* handleQueueMetricsAfterTransfer() {
    yield takeLatest(Types.RECEIVED_QUEUE_TRANSFER, function* ({ payload }) {
        const { oldTicket, updatedTicket, shouldDecreaseMetrics, shouldIncreaseMetrics } = payload
        const { queueMetrics } = yield select(getTicketStore)

        if(!shouldDecreaseMetrics && !shouldIncreaseMetrics) return

        let queueDoesNotExists = true

        const newQueueMetrics = queueMetrics.map(item => {
            if(shouldDecreaseMetrics && item.queueId === oldTicket.queueId) {
                const totalTimeToIntervene = item.totalTimeToIntervene - (oldTicket.conversationTicketId.timeUntilIntervention || 0)
                const received = item.received ? item.received - 1 : item.received

                item.inProgress = item.inProgress ? item.inProgress - 1 : item.inProgress
                item.totalTimeToIntervene = totalTimeToIntervene
                item.received = received
                item.avgTimeToIntervene = Math.round(totalTimeToIntervene / received)
            }

            if(shouldIncreaseMetrics && item.queueId === updatedTicket.queueId) {
                queueDoesNotExists = false
                const totalTimeToIntervene = item.totalTimeToIntervene + (oldTicket.conversationTicketId.timeUntilIntervention || 0)
                const received = item.received + 1

                item.inProgress = item.inProgress + 1
                item.received = received
                item.totalTimeToIntervene = totalTimeToIntervene
                item.avgTimeToIntervene = Math.round(item.totalTimeToIntervene / received)
            }

            return item
        })

        if(queueDoesNotExists && shouldIncreaseMetrics) {
            yield put(shouldReloadQueueMetrics())

            return
        }

        yield put(getQueueMetricsSuccess(newQueueMetrics))
    })
}

//* Handles attendant metrics after metrics are updated
function* handleAttendantAfterStartOfNewIntervention() {
    yield takeLatest(Types.RECEIVED_START_OF_A_NEW_INTERVENTION, function* ({ payload: { interventionTicket } }) {
        const { attendantMetrics } = yield select(getTicketStore)

        if(!attendantMetrics) return

        const existis = attendantMetrics.find(item => item.attendant === interventionTicket.attendant)

        if (!existis) {
            const newMetric = {
                inProgress: 1,
                avgTimeToSolve: 0,
                totalTimeToSolved: 0,
                solved: 0,
                attendant: interventionTicket.attendant
            }

            yield put(getAttendantMetricsSuccess([newMetric, ...attendantMetrics]))

            return
        }

        const newMetrics = attendantMetrics.map(item => {
            if(item.attendant !== interventionTicket.attendant) {
                return item
            }

            return { ...item, inProgress: item.inProgress + 1 }
        })

        yield put(getAttendantMetricsSuccess(newMetrics))
    })
}

function* handleAttendantAfterEndOfAnIntervention() {
    yield takeLatest(Types.RECEIVED_END_OF_AN_INTERVENTION, function* ({ payload: interventionTicket }) {
        const { attendantMetrics } = yield select(getTicketStore)

        if(!attendantMetrics) return

        const existis = attendantMetrics.find(item => item.attendant === interventionTicket.attendant)

        if (!existis) {
            const newMetric = {
                inProgress: 0,
                avgTimeToSolve: interventionTicket.timeToResolve || 0,
                totalTimeToSolved: interventionTicket.timeToResolve || 0,
                solved: 1,
                attendant: interventionTicket.attendant
            }

            yield put(getAttendantMetricsSuccess([newMetric, ...attendantMetrics]))

            return
        }

        const newMetrics = attendantMetrics.map(item => {
            if(item.attendant !== interventionTicket.attendant) {
                return item
            }

            const totalTimeToSolved = item.totalTimeToSolved + (interventionTicket.timeToResolve || 0)
            const solved = item.solved + 1

            return {
                ...item,
                inProgress: item.inProgress ? item.inProgress - 1 : item.inProgress,
                solved,
                totalTimeToSolved,
                avgTimeToSolve: Math.round(totalTimeToSolved / solved)
            }
        })

        yield put(getAttendantMetricsSuccess(newMetrics))
    })
}

function* handleAttendantMetricsAfterTransfer(payload) {
    const { oldTicket, updatedTicket, shouldDecreaseMetrics, shouldIncreaseMetrics } = payload
    const { attendantMetrics } = yield select(getTicketStore)

    if(!shouldDecreaseMetrics && !shouldIncreaseMetrics) return

    let attendantDoesNotExists = true

    const newAttendantMetrics = attendantMetrics.map(item => {
        if(shouldDecreaseMetrics && item.attendant === oldTicket.attendant) {
            item.inProgress = item.inProgress ? item.inProgress - 1 : item.inProgress
        }

        if(shouldIncreaseMetrics && item.attendant === updatedTicket.attendant) {
            item.inProgress = item.inProgress + 1
            attendantDoesNotExists = false
        }

        return item
    })

    if(attendantDoesNotExists && shouldIncreaseMetrics) {
        const newMetric = {
            inProgress: 1,
            avgTimeToSolve: 0,
            totalTimeToSolved: 0,
            solved: 0,
            attendant: updatedTicket.attendant
        }

        newAttendantMetrics.push(newMetric)
    }

    yield put(getAttendantMetricsSuccess(newAttendantMetrics))
}

export function* handleAttendantAfterQueueTransfer() {
    yield takeLatest(Types.RECEIVED_QUEUE_TRANSFER, function* ({ payload }) {
        yield handleAttendantMetricsAfterTransfer(payload)
    })
}

export function* handleAttendantAfterAttendantTransfer() {
    yield takeLatest(Types.RECEIVED_ATTENDANT_TRANSFER, function* ({ payload }) {
        yield handleAttendantMetricsAfterTransfer(payload)
    })
}

//* Handles is awaiting metrics after metrics are updated
function* handleIsAwaitingAfterNewInterventionRequest() {
    yield takeLatest(Types.RECEIVED_NEW_INTERVENTION_REQUEST, function* ({ payload: conversationTicket }) {
        const { isAwaitingTickets } = yield select(getTicketStore)
        const { queues } = yield select(getQueueStore)

        if(!isAwaitingTickets) return

        const queue = queues.find(queue => queue._id === conversationTicket.queueId)

        const newIsAwaitingTickets = {
          _id: conversationTicket._id,
          awaitingTime: 0,
          nameUser: conversationTicket.nameUser,
          queueName: queue ? queue.name : 'Default'
        }

        yield put(getIsAwaitingSuccess([newIsAwaitingTickets, ...isAwaitingTickets]))
    })
}

function* handleIsAwaitingAfterStartOfAnIntervention() {
    yield takeLatest(Types.RECEIVED_START_OF_A_NEW_INTERVENTION, function* ({ payload: { conversationTicket } }) {
        const { isAwaitingTickets } = yield select(getTicketStore)

        if(!isAwaitingTickets) return

        const newAwaitingTickets = isAwaitingTickets.filter(item => item._id !== conversationTicket._id)

        yield put(getIsAwaitingSuccess(newAwaitingTickets))
    })
}

//* Handles in progress metrics after metrics are updated
function* handleInProgressAfterStartOfAnIntervention() {
    yield takeLatest(Types.RECEIVED_START_OF_A_NEW_INTERVENTION, function* ({ payload: { conversationTicket, interventionTicket } }) {
        const { inProgressTickets } = yield select(getTicketStore)
        const { queues } = yield select(getQueueStore)

        if(!inProgressTickets) return

        const queue = queues.find(queue => queue._id === interventionTicket.queueId)

        const newInProgressTicket = {
            _id: interventionTicket._id,
            awaitTime: conversationTicket.timeUntilIntervention || 0,
            timeInProgress: 0,
            nameUser: interventionTicket.nameUser,
            queueName: queue ? queue.name : 'Default',
            attendant: interventionTicket.attendant
        }

        yield put(getInProgressSuccess([newInProgressTicket, ...inProgressTickets]))
    })
}

function* handleInProgressAfterEndOfAnIntervention() {
    yield takeLatest(Types.RECEIVED_END_OF_AN_INTERVENTION, function* ({ payload: interventionTicket }) {
        const { inProgressTickets } = yield select(getTicketStore)

        if(!inProgressTickets) return

        const newInProgressTickets = inProgressTickets.filter(item => item._id !== interventionTicket._id)

        yield put(getInProgressSuccess(newInProgressTickets))
    })
}

function* handleInProgressAfterTransfer(payload) {
    const { oldTicket, updatedTicket, shouldDecreaseMetrics, shouldIncreaseMetrics } = payload
    const { inProgressTickets } = yield select(getTicketStore)
    const { queues } = yield select(getQueueStore)

    if(!shouldDecreaseMetrics && !shouldIncreaseMetrics) return

    let ticketDoesNotExists = true

    const newInProgressTickets = inProgressTickets.reduce((acc, item) => {
        if (shouldDecreaseMetrics && item._id === oldTicket._id) {
            return acc
        }

        if (shouldIncreaseMetrics && item._id === updatedTicket._id) {
            const queue = queues.find(queue => queue._id === updatedTicket.queueId)

            item.attendant = updatedTicket.attendant
            item.queueName = queue ? queue.name : 'Default'
            ticketDoesNotExists = false

            acc.push(item)
        }

        return acc
    }, [])

    if(ticketDoesNotExists && shouldIncreaseMetrics) {
        const queue = queues.find(queue => queue._id === updatedTicket.queueId)

        const newTicket = {
            _id: updatedTicket._id,
            awaitTime: oldTicket.conversationTicketId.timeUntilIntervention || 0,
            timeInProgress: (new Date().getTime() - (new Date(updatedTicket.date).getTime() + (3 * 60 * 60 * 1000))) / 1000,
            nameUser: updatedTicket.nameUser,
            queueName: queue ? queue.name : 'Default',
            attendant: updatedTicket.attendant
        }


        newInProgressTickets.push(newTicket)
    }

    yield put(getInProgressSuccess(newInProgressTickets))
}

export function* handleInProgressAfterAttendantTransfer() {
    yield takeLatest(Types.RECEIVED_ATTENDANT_TRANSFER, function* ({ payload }) {
        yield handleInProgressAfterTransfer(payload)
    })
}

export function* handleInProgressAfterQueueTransfer() {
    yield takeLatest(Types.RECEIVED_QUEUE_TRANSFER, function* ({ payload }) {
        yield handleInProgressAfterTransfer(payload)
    })
}

//* Handles metrics after metrics are updated
export function* handleNewInterventionRequest() {
    yield takeLatest(Types.RECEIVED_NEW_INTERVENTION_REQUEST, function* () {
        const { metrics } = yield select(getTicketStore)

        yield put(getMetricsSuccess({
            ...metrics,
            received: metrics.received + 1,
            awaiting: metrics.awaiting + 1
        }))
    })
}

export function* handleStartOfANewIntervention() {
    yield takeLatest(Types.RECEIVED_START_OF_A_NEW_INTERVENTION, function* ({ payload: { conversationTicket } }) {
        const { metrics } = yield select(getTicketStore)

        const canDecreaseAwaiting = metrics.awaiting > 0
        const totalTimeToIntervene = metrics.totalTimeToIntervene + (conversationTicket.timeUntilIntervention || 0)

        yield put(getMetricsSuccess({
            ...metrics,
            inProgress: metrics.inProgress + 1,
            awaiting: canDecreaseAwaiting ? metrics.awaiting - 1 : metrics.awaiting,
            totalTimeToIntervene,
            avgTimeToIntervene: metrics.received ? Math.round(totalTimeToIntervene / metrics.received) : 0,
            lostTheTimeToIntervene: conversationTicket.lostTheTimeToIntervene ? metrics.lostTheTimeToIntervene + 1 : metrics.lostTheTimeToIntervene,
            uniqueLost: conversationTicket.lostTheTimeToIntervene ? metrics.uniqueLost + 1 : metrics.uniqueLost
        }))
    })
}

export function* handleEndOfAnIntervention() {
    yield takeLatest(Types.RECEIVED_END_OF_AN_INTERVENTION, function* ({ payload: interventionTicket }) {
        const { metrics } = yield select(getTicketStore)

        const canDecreaseInProgress = metrics.inProgress > 0
        const totalTimeToSolved = metrics.totalTimeToSolved + (interventionTicket.timeToResolve || 0)
        const solved = metrics.solved + 1

        yield put(getMetricsSuccess({
            ...metrics,
            inProgress: canDecreaseInProgress ? metrics.inProgress - 1 : metrics.inProgress,
            solved,
            totalTimeToSolved,
            avgTimeToSolve: solved ? Math.round(totalTimeToSolved / solved) : 0,
            lostTheTimeToSolve: interventionTicket.lostTheTimeToSolve ? metrics.lostTheTimeToSolve + 1 : metrics.lostTheTimeToSolve,
            uniqueLost: interventionTicket.lostTheTimeToSolve && !interventionTicket.lostTheTimeToIntervene ? metrics.uniqueLost + 1 : metrics.uniqueLost
        }))
    })
}

export default function* rootSaga() {
    yield all([
        fork(getMetricsSaga),
        fork(getQueueMetricsSaga),
        fork(getAttendantMetricsSaga),
        fork(getInProgressSaga),
        fork(getIsAwaitingSaga),
        fork(handleNewInterventionRequest),
        fork(handleStartOfANewIntervention),
        fork(handleEndOfAnIntervention),
        fork(handleQueueMetricsAfterNewInterventionRequest),
        fork(handleQueueMetricsAfterStartOfANewIntervention),
        fork(handleQueueMetricsAfterEndOfAnIntervention),
        fork(handleAttendantAfterStartOfNewIntervention),
        fork(handleAttendantAfterEndOfAnIntervention),
        fork(handleIsAwaitingAfterNewInterventionRequest),
        fork(handleIsAwaitingAfterStartOfAnIntervention),
        fork(handleInProgressAfterStartOfAnIntervention),
        fork(handleInProgressAfterEndOfAnIntervention),
        fork(handleInProgressAfterAttendantTransfer),
        fork(handleInProgressAfterQueueTransfer),
        fork(handleAttendantAfterQueueTransfer),
        fork(handleAttendantAfterAttendantTransfer),
        fork(handleQueueMetricsAfterTransfer)
    ]);
}

// Action Types
export const Types = {
    GET_METRICS: 'GET_METRICS',
    GET_METRICS_SUCCESS: 'GET_METRICS_SUCCESS',
    GET_QUEUE_METRICS: 'GET_QUEUE_METRICS',
    GET_QUEUE_METRICS_SUCCESS: 'GET_QUEUE_METRICS_SUCCESS',
    GET_ATTENDANT_METRICS: 'GET_ATTENDANT_METRICS',
    GET_ATTENDANT_METRICS_SUCCESS: 'GET_ATTENDANT_METRICS_SUCCESS',
    GET_IN_PROGRESS: 'GET_IN_PROGRESS',
    GET_IN_PROGRESS_SUCCESS: 'GET_IN_PROGRESS_SUCCESS',
    GET_IS_AWAITING: 'GET_IS_AWAITING',
    GET_IS_AWAITING_SUCCESS: 'GET_IS_AWAITING_SUCCESS',
    SET_LOADING_METRICS: 'SET_LOADING_METRICS',
    SET_LOADING_REPORTS: 'SET_LOADING_REPORTS',
    RECEIVED_NEW_INTERVENTION_REQUEST: 'RECEIVED_NEW_INTERVENTION_REQUEST',
    RECEIVED_START_OF_A_NEW_INTERVENTION: 'INTERVENTION_STARTED',
    RECEIVED_END_OF_AN_INTERVENTION: 'INTERVENTION_ENDED',
    SHOULD_RELOAD_QUEUE_METRICS: 'SHOULD_RELOAD_QUEUE_METRICS',
    RECEIVED_ATTENDANT_TRANSFER: 'TRANSFER_ATTENDANT',
    RECEIVED_QUEUE_TRANSFER: 'TRANSFER_QUEUE',
}

// Reducer
const INITIAL_STATE = {
    metrics: {},
    inProgressTickets: [],
    isAwaitingTickets: [],
    queueMetrics: [],
    attendantMetrics: [],
    isLoadingMetrics: false,
    isLoadingReports: false,
    shouldReloadQueueMetrics: false
}

export default function reducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case Types.GET_METRICS_SUCCESS: {
            return {
                ...state,
                metrics: action.payload
            }
        }
        case Types.GET_QUEUE_METRICS_SUCCESS: {
            return {
                ...state,
                queueMetrics: action.payload,
                shouldReloadQueueMetrics: false
            }
        }
        case Types.GET_ATTENDANT_METRICS_SUCCESS: {
            return {
                ...state,
                attendantMetrics: action.payload
            }
        }
        case Types.GET_IN_PROGRESS_SUCCESS: {
            return {
                ...state,
                inProgressTickets: action.payload
            }
        }
        case Types.GET_IS_AWAITING_SUCCESS: {
            return {
                ...state,
                isAwaitingTickets: action.payload
            }
        }
        case Types.SET_LOADING_METRICS: {
            return {
                ...state,
                isLoadingMetrics: action.payload
            }
        }
        case Types.SET_LOADING_REPORTS: {
            return {
                ...state,
                isLoadingReports: action.payload
            }
        }
        case Types.SHOULD_RELOAD_QUEUE_METRICS: {
            return {
                ...state,
                shouldReloadQueueMetrics: true
            }
        }
        default:
            return state;
    }
}

// Action Creators
export const getMetrics = (filter) => {
    return {
        type: Types.GET_METRICS,
        payload: filter
    }
}

export const getMetricsSuccess = (metrics) => {
    return {
        type: Types.GET_METRICS_SUCCESS,
        payload: metrics
    }
}

export const getQueueMetrics = (filter) => {
    return {
        type: Types.GET_QUEUE_METRICS,
        payload: filter
    }
}

export const getQueueMetricsSuccess = (metrics) => {
    return {
        type: Types.GET_QUEUE_METRICS_SUCCESS,
        payload: metrics
    }
}

export const getAttendantMetrics = (filter) => {
    return {
        type: Types.GET_ATTENDANT_METRICS,
        payload: filter
    }
}

export const getAttendantMetricsSuccess = (metrics) => {
    return {
        type: Types.GET_ATTENDANT_METRICS_SUCCESS,
        payload: metrics
    }
}

export const getInProgress = (filter) => {
    return {
        type: Types.GET_IN_PROGRESS,
        payload: filter
    }
}

export const getInProgressSuccess = (tickets) => {
    return {
        type: Types.GET_IN_PROGRESS_SUCCESS,
        payload: tickets
    }
}

export const getIsAwaiting = (filter) => {
    return {
        type: Types.GET_IS_AWAITING,
        payload: filter
    }
}

export const getIsAwaitingSuccess = (tickets) => {
    return {
        type: Types.GET_IS_AWAITING_SUCCESS,
        payload: tickets
    }
}

export const setLoadingMetrics = (isLoading) => {
    return {
        type: Types.SET_LOADING_METRICS,
        payload: isLoading
    }
}

export const setLoadingReports = (isLoading) => {
    return {
        type: Types.SET_LOADING_REPORTS,
        payload: isLoading
    }
}

export const incrementReceivedAndAwaitingTotals = (amount) => {
    return {
        type: Types.INCREASE_TOTAL_RECEIVED_AND_AWAITING,
        payload: amount
    }
}

export const decreaseTotalAwaitingAndIncrementInProgress = (amount) => {
    return {
        type: Types.DECREASE_TOTAL_AWAITING_AND_INCREMENT_IN_PROGRESS,
        payload: amount
    }
}

export const decreaseTotalInProgressAndIncrementSolved = (amount) => {
    return {
        type: Types.DECREASE_TOTAL_IN_PROGRESS_AND_INCREMENT_SOLVED,
        payload: amount
    }
}

export const receivedNewInterventionRequest = (conversationTicket) => {
    return {
        type: Types.RECEIVED_NEW_INTERVENTION_REQUEST,
        payload: conversationTicket
    }
}

export const receivedStartOfANewIntervention = (conversationTicket, interventionTicket) => {
    return {
        type: Types.RECEIVED_START_OF_A_NEW_INTERVENTION,
        payload: { conversationTicket, interventionTicket }
    }
}

export const receivedEndOfAnIntervention = (interventionTicket) => {
    return {
        type: Types.RECEIVED_END_OF_AN_INTERVENTION,
        payload: interventionTicket
    }
}

export const shouldReloadQueueMetrics = () => {
    return {
        type: Types.SHOULD_RELOAD_QUEUE_METRICS,
    }
}

export const receivedAttendantTransfer = (oldTicket, updatedTicket, shouldDecreaseMetrics, shouldIncreaseMetrics) => {
    return {
        type: Types.RECEIVED_ATTENDANT_TRANSFER,
        payload: { oldTicket, updatedTicket, shouldDecreaseMetrics, shouldIncreaseMetrics }
    }
}

export const receivedQueueTransfer = (oldTicket, updatedTicket, shouldDecreaseMetrics, shouldIncreaseMetrics) => {
    return {
        type: Types.RECEIVED_QUEUE_TRANSFER,
        payload: { oldTicket, updatedTicket, shouldDecreaseMetrics, shouldIncreaseMetrics }
    }
}

export const Types = {
    ERROR_LOG_LIST:"ERROR_LOG_LIST",
    ERROR_LOG_LIST_SUCCESS:"ERROR_LOG_LIST_SUCCESS",
    ACTION_LOG_LIST:"ACTION_LOG_LIST",
    ACTION_LOG_LIST_SUCCESS:"ACTION_LOG_LIST_SUCCESS",
    SET_IS_LOADING_ACTION_LOG: "SET_IS_LOADING_ACTION_LOG",
    RECEIVED_NEW_ACTION: "RECEIVED_NEW_ACTION"
};

const INITIAL_STATE = {
    errors: [],
    actions:[],
    isLoadingActionLog: false
};

export default function Logs(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.ERROR_LOG_LIST_SUCCESS: {
        return {
            ...state,
            errors: action.payload
        }
    }
    case Types.ACTION_LOG_LIST_SUCCESS: {
        return {
            ...state,
            actions: action.payload
        }
    }
    case Types.SET_IS_LOADING_ACTION_LOG: {
        return {
            ...state,
            isLoadingActionLog: action.payload
        }
    }
    default:
      return state;
  }
}

export const errorLogList = (data) => {
    return {
        type: Types.ERROR_LOG_LIST,
        payload: data
    };
};

export const errorLogListSuccess = (errors) => {
    return {
        type: Types.ERROR_LOG_LIST_SUCCESS,
        payload: errors
    };
};

export const actionLogList = (data) => {
    return {
        type: Types.ACTION_LOG_LIST,
        payload: data
    };
};

export const actionLogListSuccess = (errors) => {
    return {
        type: Types.ACTION_LOG_LIST_SUCCESS,
        payload: errors
    };
};

export const setIsLoadingActionLog = (isLoading) => {
  return {
      type: Types.SET_IS_LOADING_ACTION_LOG,
      payload: isLoading
  }
}

export const receivedNewAction = (action) => {
  return {
      type: Types.RECEIVED_NEW_ACTION,
      payload: action
  }
}

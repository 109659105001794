export const Types = {
  AGENT_LIST: 'AGENT_LIST',
  AGENT_LIST_SUCCESS: 'AGENT_LIST_SUCCESS',
  AGENT_ACTION_ERROR: 'AGENT_ACTION_ERROR',
  AGENT_MODAL_VISIBLE: 'AGENT_MODAL_VISIBLE',
  AGENT_DETAIL_SHOW: 'AGENT_DETAIL_SHOW',
  AGENT_DELETE: 'AGENT_DELETE',
  AGENT_UPDATE: 'AGENT_UPDATE',
  AGENT_CREATE: 'AGENT_CREATE',
  SET_IS_LOADING: 'SET_IS_LOADING',
  LIST_PLAYGROUND_MESSAGES: 'LIST_PLAYGROUND_MESSAGES',
  LIST_SUCCESS_PLAYGROUND_MESSAGES: 'LIST_SUCCESS_PLAYGROUND_MESSAGES',
  ADD_MESSAGE_TO_PLAYGROUND_MESSAGES: 'ADD_MESSAGE_TO_PLAYGROUND_MESSAGES',
  REGISTRATION_TEST_MODAL_VISIBLE: 'REGISTRATION_TEST_MODAL_VISIBLE',
  LIST_CURATORSHIP_TESTS: 'LIST_CURATORSHIP_TESTS',
  LIST_SUCCESS_CURATORSHIP_TESTS: 'LIST_SUCCESS_CURATORSHIP_TESTS',
  ADD_CURATORSHIP_TEST: 'ADD_CURATORSHIP_TEST',
  ADD_CURATORSHIP_TEST_SUCCESS: 'ADD_CURATORSHIP_TEST_SUCCESS',
  DELETE_CURATORSHIP_TEST: 'DELETE_CURATORSHIP_TEST',
  DELETE_CURATORSHIP_TEST_SUCCESS: 'DELETE_CURATORSHIP_TEST_SUCCESS',
  UPDATE_CURATORSHIP_TEST: 'UPDATE_CURATORSHIP_TEST',
  UPDATE_CURATORSHIP_TEST_SUCCESS: 'UPDATE_CURATORSHIP_TEST_SUCCESS',
  TRAIN_CURATORSHIP_TEST: 'TRAIN_CURATORSHIP_TEST',
  TRAIN_CURATORSHIP_TEST_SUCCESS: 'TRAIN_CURATORSHIP_TEST_SUCCESS'
}

const INITIAL_STATE = {
  agent: {},
  agentPlaygroundMessages: [],
  modalAgentVisible: false,
  modalRegistrationTestVisible: false,
  isLoading: false,
  create: false,
  curatorshipTests: []
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
     case Types.AGENT_MODAL_VISIBLE: {
      return {
        ...state,
          modalAgentVisible: action.payload.visible,
          create: action.payload.create
      }
    } case Types.AGENT_DETAIL_SHOW: {
      return {
        ...state,
        agent: action.payload
      }
    } case Types.AGENT_ACTION_ERROR: {
      return {
        ...state
      }
    } case Types.LIST_SUCCESS_PLAYGROUND_MESSAGES: {
      return {
        ...state,
        agentPlaygroundMessages: action.payload
      }
    } case Types.SET_IS_LOADING: {
      return { 
        ...state,
        isLoading: action.payload
      }
    } case Types.ADD_MESSAGE_TO_PLAYGROUND_MESSAGES: {
      return {
        ...state,
        agentPlaygroundMessages: [...state.agentPlaygroundMessages, action.payload]
      }
    } case Types.REGISTRATION_TEST_MODAL_VISIBLE: {
      return {
        ...state,
        modalRegistrationTestVisible: action.payload.visible,
        create: action.payload.create
      }
    } case Types.LIST_SUCCESS_CURATORSHIP_TESTS: {
      return {
        ...state,
        curatorshipTests: action.payload
      }
    } case Types.ADD_CURATORSHIP_TEST_SUCCESS: {
      return {
        ...state,
        curatorshipTests: action.payload
      }
    }  
    
    case Types.DELETE_CURATORSHIP_TEST_SUCCESS: {
      return {
        ...state,
        curatorshipTests: action.payload
      }
    } case Types.UPDATE_CURATORSHIP_TEST_SUCCESS: {
    
      return {
        ...state,
        curatorshipTests: action.payload
      }
    } case Types.TRAIN_CURATORSHIP_TEST_SUCCESS: {
      return {
        ...state,
        curatorshipTests: action.payload
      }
    }
    default:
      return state;
  }
}

export const agentActionError = () => {
  return {
    type: Types.AGENT_ACTION_ERROR
  }
}

export const agentModalVisible = (visible, create) => {
  return {
    type: Types.AGENT_MODAL_VISIBLE,
    payload: {
      visible,
      create
    }
  }
}

export const agentDetailShow = (agent) => {
  return {
    type: Types.AGENT_DETAIL_SHOW,
    payload: agent
  }
}

export const setIsLoading = (isLoading) => {
  return {
    type: Types.SET_IS_LOADING,
    payload: isLoading
  }
}

export const addMessageToAgentPlaygroundMessages = (message) => {
  return {
    type: Types.ADD_MESSAGE_TO_PLAYGROUND_MESSAGES,
    payload: message
  }
}

export const listAgentPlaygroundMessages = (agentId, userEmail) => {
  return {
    type: Types.LIST_PLAYGROUND_MESSAGES,
    payload: { agentId, userEmail }
  }
}

export const listSuccessPlaygroundMessages = (agentPlaygroundMessages) => {
  return {
    type: Types.LIST_SUCCESS_PLAYGROUND_MESSAGES,
    payload: agentPlaygroundMessages
  }
}

export const registrationTestModalVisible = (visible, create) => {
  return {
    type: Types.REGISTRATION_TEST_MODAL_VISIBLE,
    payload: {
      visible,
      create
    }
  }
}

export const listCuratorshipTests = (agentId) => {
  return {
    type: Types.LIST_CURATORSHIP_TESTS,
    payload: agentId
  }
}

export const listSuccessCuratorshipTests = (curatorshipTests) => {  
  return {
    type: Types.LIST_SUCCESS_CURATORSHIP_TESTS,
    payload: curatorshipTests
  }
}

export const addCuratorshipTest = (test) => {
  return {
    type: Types.ADD_CURATORSHIP_TEST,
    payload: test
  }
}

export const deleteCuratorshipTest = (test) => {
  return {
    type: Types.DELETE_CURATORSHIP_TEST,
    payload: test
  }
}

export const updateCuratorshipTest = (test) => {
  return {
    type: Types.UPDATE_CURATORSHIP_TEST,
    payload: test
  }
}

export const trainCuratorshipTest = (agentID) => {
  return {
    type: Types.TRAIN_CURATORSHIP_TEST,
    payload: agentID
  }
}
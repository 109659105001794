import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import { getMessages } from "../../services/PlaygroundMessages";
import {
  Types,
  listSuccessCuratorshipTests,
  listSuccessPlaygroundMessages,
  setIsLoading
} from "../reducers/Agent"
import CuratorService from '../../services/Curatorship'
import { errorMessage, successMessage } from "../actions/FeedbackMessage"

const getAgentStore = state => state.agents;
export function* getPlaygroundMessagesSaga() {
    yield takeLatest(Types.LIST_PLAYGROUND_MESSAGES, function* ({ payload: filter }) {
        try {
            yield put(setIsLoading(true))
            const messages = yield call(getMessages, filter)
            yield put(listSuccessPlaygroundMessages(messages))
        } catch(error) {
            yield put(errorMessage(error))
        } finally {
            yield put(setIsLoading(false))
        }
    })
}

export function* getCuratorshipTestsSaga() {
    yield takeLatest(Types.LIST_CURATORSHIP_TESTS, function* ({payload}) {
        try {
            const tests = yield call(CuratorService.list, payload)
            
            yield put(listSuccessCuratorshipTests(tests))
        } catch(error) {
            yield put(errorMessage(error))
        }
    })
}

export function* createCuratorshipTestSaga() {
    yield takeLatest(Types.ADD_CURATORSHIP_TEST, function* ({ payload }) {
        try {
            yield call(CuratorService.create, payload)
            const { agent } = yield select(getAgentStore)
            const tests = yield call(CuratorService.list, agent._id)
            yield put(listSuccessCuratorshipTests(tests))
        } catch(error) {
            yield put(errorMessage(error))
        }
    })
}

export function* updateCuratorshipTestSaga() {
    yield takeLatest(Types.UPDATE_CURATORSHIP_TEST, function* ({ payload }) {
        try {
            yield call(CuratorService.update, payload)
            const { agent } = yield select(getAgentStore)
            const tests = yield call(CuratorService.list, agent._id)
            yield put(listSuccessCuratorshipTests(tests))
        } catch(error) {
            yield put(errorMessage(error))
        }
    })
}

export function* deleteCuratorshipTestSaga() {
    yield takeLatest(Types.DELETE_CURATORSHIP_TEST, function* ({ payload }) {
        try {
            yield call(CuratorService.remove, payload)
            const { agent } = yield select(getAgentStore)
            const tests = yield call(CuratorService.list, agent._id)
            yield put(listSuccessCuratorshipTests(tests))
        } catch(error) {
            yield put(errorMessage(error))
        }
    })
}

export function* trainCuratorshipTestSaga() {
    yield takeLatest(Types.TRAIN_CURATORSHIP_TEST, function* ({ payload }) {
        try {
            yield call(CuratorService.train, payload)
            yield put(successMessage('Treinamento iniciado com sucesso!'))
        } catch(error) {
            yield put(errorMessage(error))
        }
    })
}

export default function* rootSaga() {
    yield all([
        fork(getPlaygroundMessagesSaga),
        fork(getCuratorshipTestsSaga),
        fork(createCuratorshipTestSaga),
        fork(updateCuratorshipTestSaga),
        fork(deleteCuratorshipTestSaga),
        fork(trainCuratorshipTestSaga)
    ]);
}

import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'
const BASE_URL = process.env.REACT_APP_FILE_MANAGER_URL
const SENDER_TOKEN = process.env.REACT_APP_FILE_MANAGER_SENDER_TOKEN

const BASE_SGBD = process.env.REACT_APP_SGBD_URL

class Files {
   
    static async updateDocuments(payload) {
        try {
          
            return await Api.post('/update-document', payload, {
                path: BASE_SGBD,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        } catch (err) {
            throw handleError(err)
        }
    }

    static async list(id) {
        try {
            return await Api.get(`/public/${id}`, {path: BASE_URL, token:""})
        } catch (err) {
            throw handleError(err)
        }
    }

    static async remove(data) {
        try {
            const {agent, name} = data
            await Api.delete(`/public/${agent}/${name}`,{path: BASE_URL, token: SENDER_TOKEN})
            return await Api.post('/delete-document', {"cod_agents": agent,"file_id": name}, {
                path: BASE_SGBD,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        } catch (err) {
            throw handleError(err)
        }
    }
    

    static async train(payload) {
        try {
            return await Api.post('/train-document', payload, {
                path: BASE_SGBD,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 600000 
            });
        } catch (err) {
            throw handleError(err);
        }
    }
    
}

export default Files

export const Types = {
  'SET_SHOULD_LISTEN_TO_DATA_UPDATES': 'SET_SHOULD_LISTEN_TO_DATA_UPDATES',
}

const initialState = {
  shouldListenToDataUpdates: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_SHOULD_LISTEN_TO_DATA_UPDATES: {
      return {
        ...state,
        shouldListenToDataUpdates: action.payload
      }
    }
    default:
      return state
  }
}

export function setShouldListenToDataUpdates(payload) {
  return {
    type: Types.SET_SHOULD_LISTEN_TO_DATA_UPDATES,
    payload,
  }
}

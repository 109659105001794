import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { Types, inactivityList, findByIdSuccess } from "../reducers/Inactivity";
import InactivityService from "../../services/Inactivity";

import { componentLoading, buttonLoading, toggleModal } from "../actions/loading";
import { errorMessage } from "../actions/FeedbackMessage";

const createRequest = async (inactivity) =>
  await InactivityService.create(inactivity)
    .then(res => res)
    .catch(err => { throw err });

const updateRequest = async (inactivity) =>
  await InactivityService.update(inactivity)
    .then(res => res)
    .catch(err => { throw err });

const removeRequest = async (id) =>
  await InactivityService.remove(id)
    .then(res => res)
    .catch(err => { throw err });

const listRequest = async () =>
  await InactivityService.list()
    .then(res => res)
    .catch(err => { throw err });

const wordRequest = async () =>
  await InactivityService.wordCloudByInactivity()
    .then(res => res)
    .catch(err => { throw err });

const findByIdRequest = async (id) =>
  await InactivityService.findById(id)
    .then(res => res)
    .catch(err => { throw err });

// Sagas para cada ação
function* create({ payload }) {
  try {
    yield put(buttonLoading(true));
    yield call(createRequest, payload);
    yield put(inactivityList());  
    yield put(buttonLoading(false));
    yield put(toggleModal(false));
  } catch (error) {
    yield put(errorMessage(error));
    yield put({ type: Types.INACTIVITY_CREATE_ERROR, payload: error });
    yield put(buttonLoading(false));
    yield put(toggleModal(false));
  }
}

function* update({ payload }) {
  try {
    yield put(buttonLoading(true));
    yield call(updateRequest, payload);
    yield put(inactivityList()); 
    yield put(buttonLoading(false));
    yield put(toggleModal(false));
  } catch (error) {
    yield put(errorMessage(error));
    yield put({ type: Types.INACTIVITY_UPDATE_ERROR, payload: error });
    yield put(buttonLoading(false));
    yield put(toggleModal(false));
  }
}

function* remove({ payload }) {
  try {
    yield call(removeRequest, payload);
    yield put({ type: Types.INACTIVITY_DELETE_SUCESS });
    yield put(inactivityList());  
  } catch (error) {
    yield put(errorMessage(error));
    yield put({ type: Types.INACTIVITY_DELETE_ERROR, payload: error });
  }
}

function* list() {
  try {
    const inactivities = yield call(listRequest);
    yield put({ type: Types.INACTIVITY_LIST_SUCESS, payload: inactivities });
  } catch (error) {
    yield put({ type: Types.INACTIVITY_LIST_ERROR, payload: error });
  }
}

function* wordcloudFNC() {
  try {
    yield put(componentLoading(true));
    const words = yield call(wordRequest);
    yield put({ type: Types.INACTIVITY_WORD_SUCESS, payload: words });
    yield put(componentLoading(false));
  } catch (error) {
    yield put({ type: Types.INACTIVITY_LIST_ERROR, payload: error });
  }
}

function* findById({ payload }) {
  try {
    const inactivity = yield call(findByIdRequest, payload);
    yield put(findByIdSuccess(inactivity));
  } catch (error) {
    yield put({ type: Types.FIND_BY_ID_ERROR, payload: error });
  }
}

// Watchers
export function* createInactivity() {
  yield takeLatest(Types.INACTIVITY_CREATE, create);
}

export function* updateInactivity() {
  yield takeLatest(Types.INACTIVITY_UPDATE, update);
}

export function* deleteInactivity() {
  yield takeLatest(Types.INACTIVITY_DELETE, remove);
}

export function* listInactivities() {
  yield takeLatest(Types.INACTIVITY_LIST, list);
}

export function* wordList() {
  yield takeLatest(Types.INACTIVITY_WORD, wordcloudFNC);
}

export function* findInactivityById() {
  yield takeLatest(Types.FIND_BY_ID, findById);
}

export default function* rootSaga() {
  yield all([
    fork(listInactivities),
    fork(wordList),
    fork(deleteInactivity),
    fork(createInactivity),
    fork(updateInactivity),
    fork(findInactivityById)
  ]);
}

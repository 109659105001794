import axios from 'axios';

require('dotenv').config({ silent: true })

const API_ROOT = process.env.REACT_APP_API_URL

const _getClient = (options) => {
	let token
	let path

	if(options){
		token = options.token
		path = options.path
	}

	const auth = token || `Bearer ${JSON.parse(localStorage.getItem("token"))}`
	const baseUrl = path || API_ROOT

	return axios.create({
		baseURL: baseUrl,
		timeout: 10000,
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: auth
		}
	})
}

const Api = {
	get(path, options, params){
		const client = _getClient(options)
		return client.get(path, { params }).then(response => response.data)
	},
	post(path, payload, options){
		const client = _getClient(options)
		return client.post(path, payload).then(response => response.data)
	},
	patch(path, payload, options) {
		const client = _getClient(options)
		return client.patch(path, payload).then(response => response.data)
	},
	put(path, payload, options) {
		const client = _getClient(options)
		return client.put(path, payload).then(response => response.data)
	},
	delete(path, options) {
		const client = _getClient(options)
		return client.delete(path).then(response => response.data).catch(err => { throw err })
	}
}

export default Api

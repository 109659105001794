import {
  CONVERSATION_TOTAL_MESSAGES,
  CONVERSATION_TOTAL_UNDERSTAND,
  CONVERSATION_TOP_INTENTS,
  CONVERSATION_TOP_ENTITIES,
  CONVERSATION_MESSAGES,
  CONVERSATION_IN_PROGRESS,
  CONVERSATION_MESSAGES_SUCCESS,
  CONVERSATION_IN_PROGRESS_REMOVE_FROM_LIST,
  CONVERSATION_IN_PROGRESS_UPDATE,
  SET_LOADING_CONVERSATION_ITEM,
  RECEIVED_NEW_MESSAGE_UPDATE_TOTAL_MESSAGES,
  RECEIVED_NEW_MESSAGE_UPDATE_TOTAL_UNDERSTAND,
  RECEIVED_NEW_ENTITIES,
  RECEIVED_NEW_INTENTS
} from "../../constants/ActionTypes";

export const countMessagesByCompanyId = (filters) => {
  return {
    type: CONVERSATION_TOTAL_MESSAGES,
    payload: filters
  };
};

export const countUnderstandByCompanyId = (filters) => {
  return {
    type: CONVERSATION_TOTAL_UNDERSTAND,
    payload: filters
  };
};

export const findTopIntentsByCompanyId = (filters) => {
  return {
    type: CONVERSATION_TOP_INTENTS,
    payload: filters
  };
};

export const findTopEntitiesByCompanyId = (filters) => {
  return {
    type: CONVERSATION_TOP_ENTITIES,
    payload: filters
  };
};

export const findMessages = (filters) => {
  return {
    type: CONVERSATION_MESSAGES,
    payload: filters
  }
}

export const findConversationInProgressByCompanyId = (filters) => {
  return {
    type: CONVERSATION_IN_PROGRESS,
    payload: filters
  };
};

export const findMessagesSuccess = (messages) => {
  return {
    type: CONVERSATION_MESSAGES_SUCCESS,
    payload: messages
  }
}

export const removeFromList = (conversationId) => {
  return {
      type: CONVERSATION_IN_PROGRESS_REMOVE_FROM_LIST,
      payload: conversationId
  }
}

export const updateConversationInProgress = (message) => {
  return {
      type: CONVERSATION_IN_PROGRESS_UPDATE,
      payload: message
  }
}

export const setLoadingConversationItem = (item, status) => {
  return {
      type: SET_LOADING_CONVERSATION_ITEM,
      payload: { item, status }
  }
}

export const receivedNewMessageUpdateTotalMessages = (message) => {
  return {
      type: RECEIVED_NEW_MESSAGE_UPDATE_TOTAL_MESSAGES,
      payload: message
  }
}

export const receivedNewMessageUpdateTotalUnderstand = (message) => {
  return {
      type: RECEIVED_NEW_MESSAGE_UPDATE_TOTAL_UNDERSTAND,
      payload: message
  }
}

export const receivedNewEntities = (entities) => {
  return {
      type: RECEIVED_NEW_ENTITIES,
      payload: entities
  }
}

export const receivedNewIntents = (intents) => {
  return {
      type: RECEIVED_NEW_INTENTS,
      payload: intents
  }
}

// Action Types
export const Types = {
    LIST_ALL: 'LIST_ALL',
    LIST_ALL_SUCCESS: 'LIST_ALL_SUCCESS',
    SET_IS_LOADING_FEEDBACKS: 'SET_IS_LOADING_FEEDBACKS',
    INSERT_NEW_FEEDBACK: 'INSERT_NEW_FEEDBACK'
};

// Reducer
const INITIAL_STATE = {
    feedbacks:[],
    isLoadingFeedbacks: false
}

export default function reducer(state = INITIAL_STATE, action) {
    if(action.type === Types.LIST_ALL_SUCCESS ){
        return {
            ...state,
            feedbacks: action.payload
        }
    }if(action.type === Types.SET_IS_LOADING_FEEDBACKS ){
        return {
            ...state,
            isLoadingFeedbacks: action.payload
        }
    }if(action.type === Types.INSERT_NEW_FEEDBACK ){
        return {
            ...state,
            feedbacks: [action.payload, ...state.feedbacks]
        }
    }else{
        return state
    }
}

// Action Creators
export function listAll(filters) {
    return {
        type: Types.LIST_ALL,
        payload: filters
    }
}

export function listAllSuccess(feedbacks) {
    return {
        type: Types.LIST_ALL_SUCCESS,
        payload: feedbacks
    }
}

export function setIsLoadingFeedbacks(isLoading) {
    return {
        type: Types.SET_IS_LOADING_FEEDBACKS,
        payload: isLoading
    }
}

export function insertNewFeedback(feedback) {
    return {
        type: Types.INSERT_NEW_FEEDBACK,
        payload: feedback
    }
}

// Action Types
export const Types = {
    LIST: 'LIST',
    LIST_SUCCESS: 'LIST_SUCCESS',
    REMOVE: 'REMOVE',
    ADD_FILE: 'ADD_FILE',
    TRAIN_AGENT: 'TRAIN_AGENT',
    TRAIN_SUCCESS: 'TRAIN_SUCCESS'
}

// Reducer
const INITIAL_STATE = {
    files:[    
    ],
    train_status: false,
    train_message: ''
}

export default function reducer(state = INITIAL_STATE, action) {
    if(action.type === Types.LIST_SUCCESS ){
        return {
            ...state,
            files: action.payload
        }
    }else{
        return state
    }
}

// Action Creators
export function list(id) {
    return {
        type: Types.LIST,
        payload: id
    }
}

export function train(payload) {
    return {
        type: Types.TRAIN_AGENT,
        payload: payload
    }
}

export function addFile(payload) {
    return {
        type: Types.ADD_FILE,
        payload: payload
    }
}
export function remove(name) {
    return {
        type: Types.REMOVE,
        payload: name
    }
}

export function listSuccess(files) {
    return {
        type: Types.LIST_SUCCESS,
        payload: files
    }
}


export function trainSuccess(payload) {
    return {
        type: Types.TRAIN_SUCCESS,
        payload: payload
    }
}




import Api from './Api'
import { handleError } from '../util/ApiErrorUtil'

class PlaygroundMessages {
    static async getMessages(filters) {
        try {
            const response = await Api.get('/playground-messages', {}, filters);

            return response;
        } catch (err) {
            throw handleError(err);
        }
    }
}

export const getMessages = async (filters) => await PlaygroundMessages.getMessages(filters);

export default PlaygroundMessages;

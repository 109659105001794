import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";
import ErrorLogService from "../../services/Logs";

import { componentLoading } from "../actions/loading";

import {
    Types,
    errorLogListSuccess,
    actionLogListSuccess,
    setIsLoadingActionLog
} from "../reducers/Logs";

import { errorMessage } from "../actions/FeedbackMessage";

const getStoreLogs = state => state.logs;

const listErrorLogRequest = async payload =>
    await ErrorLogService.list(payload)
        .then(errors => errors)
        .catch(error => { throw error });

const listActionLogRequest = async payload =>
    await ErrorLogService.listActions(payload)
        .then(actions => actions)
        .catch(error => { throw error });


function* listErrorLog({ payload }) {
    try {
        yield put(componentLoading(true))
        const errors = yield call(listErrorLogRequest, payload)
        yield put(errorLogListSuccess(errors))
        yield put(componentLoading(false))
    } catch (error) {
        yield put(componentLoading(false))
        yield put(errorMessage("Erro ao exibir o console de erros"))
    }
}

function* listActionLog({ payload }) {
    try {
        yield put(setIsLoadingActionLog(true))
        yield put(componentLoading(true))
        const actions = yield call(listActionLogRequest, payload)
        yield put(actionLogListSuccess(actions))
        yield put(componentLoading(false))
    } catch (error) {
        yield put(componentLoading(false))
        yield put(errorMessage(error))
    } finally {
        yield put(setIsLoadingActionLog(false))
    }
}

function* updateActionLog({ payload: newAction }) {
  const { actions } = yield select(getStoreLogs);

  const exists = actions.find(action => action._id === newAction.actionName);

  if(exists) {
    exists.total += 1;
  } else {
    actions.push({
      _id: newAction.actionName,
      total: 1
    });
  }

  actions.sort((a, b) => b.total - a.total);

  yield put(actionLogListSuccess([...actions]));
}

export function* errorLogListSaga() {
    yield takeLatest(Types.ERROR_LOG_LIST, listErrorLog);
}

export function* actionLogListSaga() {
    yield takeLatest(Types.ACTION_LOG_LIST, listActionLog);
}

export function* handleReceivedNewAction() {
    yield takeLatest(Types.RECEIVED_NEW_ACTION, updateActionLog);
}

export default function* rootSaga() {
    yield all([
        fork(errorLogListSaga),
        fork(actionLogListSaga),
        fork(handleReceivedNewAction)
    ]);
}

import { all, call, fork, put, select, takeLatest } from "redux-saga/effects";

import {
	CONVERSATION_IN_PROGRESS,
	CONVERSATION_IN_PROGRESS_SUCCESS,
	CONVERSATION_TOTAL_MESSAGES,
	CONVERSATION_TOTAL_UNDERSTAND,
	CONVERSATION_TOTAL_MESSAGES_SUCCESS,
	CONVERSATION_TOTAL_UNDERSTAND_SUCCESS,
	CONVERSATION_TOP_INTENTS,
	CONVERSATION_TOP_INTENTS_SUCCESS,
	CONVERSATION_TOP_ENTITIES_SUCCESS,
	CONVERSATION_TOP_ENTITIES,
	CONVERSATION_MESSAGES,
  RECEIVED_NEW_MESSAGE_UPDATE_TOTAL_MESSAGES,
  RECEIVED_NEW_MESSAGE_UPDATE_TOTAL_UNDERSTAND,
  RECEIVED_NEW_ENTITIES,
  RECEIVED_NEW_INTENTS
} from "../../constants/ActionTypes";

import { findMessagesSuccess, setLoadingConversationItem, updateConversationInProgress } from "../actions/Conversation"
import { chatLoading, Types, findByIdSuccess, findUnderstandingByIdSuccess, refreshCurrentContact } from "../reducers/Chat"

import { errorMessage } from "../actions/FeedbackMessage"

import ConversationService from "../../services/Conversation";

import { componentLoading, buttonLoading } from "../actions/loading";

const getConversationStore = state => state.conversation;

const countByCompanyIdRequest = async (payload) => ConversationService.countAllByCompanyId(payload)
    .then((conversations) => conversations)
    .catch((err) => { throw err })

const findMessagesRequest = async (payload) =>
	await ConversationService.findMessages(payload)
		.then((conversations) => conversations)
		.catch((err) => {
			throw err
		})

const findMessagesByIdRequest = async (payload) =>
	await ConversationService.findMessagesById(payload)
		.then((conversations) => conversations)
		.catch((err) => {
			throw err
		})

const findTopIntentsByCompanyIdRequest = async (payload) =>
	await ConversationService.findTopIntentsByCompanyId(payload)
		.then((topIntents) => topIntents)
		.catch((err) => {
			throw err
		})

const findTopEntitiesByCompanyIdRequest = async (payload) =>
	await ConversationService.findTopEntitiesByCompanyId(payload)
		.then((topEntities) => topEntities)
		.catch((err) => {
			throw err
		})

const findConversationsInProgressByCompanyIdRequest = async (payload) =>
	await ConversationService.findConversationsInProgressByCompanyId(payload)
		.then((topEntities) => topEntities)
		.catch((err) => {
			throw err
		})

const startInterventionRequest = async (payload) => ConversationService.startIntervention(payload)
	.then(data => data)
	.catch(error => { throw error });

function* countMessagesByCompanyIdService({ payload }) {
	try {
    yield put(setLoadingConversationItem('isLoadingTotalMessages', true))

		const payloadFromUser = {
			filters: {
				...payload.filters,
				from:"user"
			}
		}

    	const payloadFromBot = {
      		filters: {
        		...payload.filters,
        		from:"bot"
      		}
		}

		const results = yield all([
			call(countByCompanyIdRequest, payloadFromUser),
			call(countByCompanyIdRequest, payloadFromBot)
		])

		//First result -> totalReceivedMessages
		//Second result -> totalSendedMessages
		const totalMessages = results[0] + results[1]

		const payloadRetorno = {
			totalMessages,
			totalReceivedMessages : results[0],
			totalSendedMessages : results[1]
		}

    	yield put({ type: CONVERSATION_TOTAL_MESSAGES_SUCCESS, payload: payloadRetorno })
  } catch (error) {
    yield put(errorMessage(error))
  } finally {
    yield put(setLoadingConversationItem('isLoadingTotalMessages', false))
  }
}

function* countUnderstandByCompanyIdService({ payload }) {
	try {
    yield put(setLoadingConversationItem('isLoadingTotalUnderstand', true))

		const payloadFromBot = {
			companyId:payload.companyId,
			filters: {
				...payload.filters,
				from:"bot"
			}
		}

		const payloadMasterUnderstanding = {
			companyId:payload.companyId,
			filters: {
				...payload.filters,
				from:"bot",
				understandingMaster: false
			}
		}

		const results = yield all([
			call(countByCompanyIdRequest, payloadFromBot),
			call(countByCompanyIdRequest, payloadMasterUnderstanding)
		])

		//First result -> totalMessagesFromBot
		//Second result -> totalNotUnderstandMessages
		const totalUnderstandMessages = parseInt(results[0]) - parseInt(results[1])

		const payloadRetorno = {
			totalMessagesFromBot: parseInt(results[0]),
			totalNotUnderstandMessages: parseInt(results[1]),
			totalUnderstandMessages
		}

		yield put({ type: CONVERSATION_TOTAL_UNDERSTAND_SUCCESS, payload: payloadRetorno })

	} catch (error) {
		yield put(errorMessage(error))
	} finally {
    yield put(setLoadingConversationItem('isLoadingTotalUnderstand', false))
  }
}

function* findTopIntentsByCompanyIdService({ payload }) {
	try {
    yield put(setLoadingConversationItem('isLoadingTopIntents', true))
		const topIntents = yield call(findTopIntentsByCompanyIdRequest, payload)
		yield put({ type: CONVERSATION_TOP_INTENTS_SUCCESS, payload: topIntents })

  } catch (error) {
    yield put(errorMessage(error))
  } finally {
    yield put(setLoadingConversationItem('isLoadingTopIntents', false))
  }
}

function* findTopEntitiesByCompanyIdService({ payload }) {
	try {
    yield put(setLoadingConversationItem('isLoadingTopEntities', true))
		const topEntities = yield call(findTopEntitiesByCompanyIdRequest, payload)
		yield put({ type: CONVERSATION_TOP_ENTITIES_SUCCESS, payload: topEntities })

  } catch (error) {
    yield put(errorMessage(error))
  } finally {
    yield put(setLoadingConversationItem('isLoadingTopEntities', false))
  }
}

function* findMessages({ payload }) {
  try {
    yield put(componentLoading(true))
    const messages = yield call(findMessagesRequest, payload)
    yield put(findMessagesSuccess(messages))
    yield put(componentLoading(false))
  } catch (error) {
    yield put(componentLoading(false))
    yield put(errorMessage(error))
  }
}

function* findMessagesById({ payload }) {
	try {
		const message = yield call(findMessagesByIdRequest, payload)
		yield put(findByIdSuccess(message))
		yield put(chatLoading(false))
	} catch (error) {
		yield put(errorMessage(("Ocorreu um erro ao pesquisar as mensagens")))
		yield put(chatLoading(false))
	}
}
function* findUnderstandingMessagesById({ payload }) {
	try {
		yield put(chatLoading(true))
		const message = yield call(findMessagesByIdRequest, payload)
		yield put(chatLoading(false))
		yield put(findUnderstandingByIdSuccess(message))
	} catch (error) {
		yield put(errorMessage(("Ocorreu um erro ao pesquisar as mensagens")))
		yield put(chatLoading(false))
	}
}

function* findConversationsInProgressByCompanyIdService({ payload }) {
	try {
		const conversations = yield call(findConversationsInProgressByCompanyIdRequest, payload)
		yield put({ type: CONVERSATION_IN_PROGRESS_SUCCESS, payload: conversations })

	} catch (error) {
		yield put(errorMessage(("Ocorreu um erro ao pesquisar as conversas em andamento")))
	}
}

function* startIntervention({ payload }) {
	try {
		yield put(buttonLoading(true))
		const data = yield call(startInterventionRequest, payload)

		yield put(updateConversationInProgress(data))
		yield put(refreshCurrentContact(true))
		yield put(buttonLoading(false))
	} catch (error) {
		yield put(buttonLoading(false))
		yield put(errorMessage(error))
	}
}

function* handleUpdateTotalMessages({ payload: messageInfo }) {
  const { totalMessages } = yield select(getConversationStore)

  const newTotalMessages = {
    totalMessages: totalMessages.totalMessages + 1,
    totalReceivedMessages : messageInfo.from === 'user' ? totalMessages.totalReceivedMessages + 1 : totalMessages.totalReceivedMessages,
    totalSendedMessages : messageInfo.from === 'bot' ? totalMessages.totalSendedMessages + 1 : totalMessages.totalSendedMessages
  }

  yield put({ type: CONVERSATION_TOTAL_MESSAGES_SUCCESS, payload: newTotalMessages })
}

function* handleUpdateTotalUnderstand({ payload: messageInfo }) {
  if(messageInfo.from !== 'bot') return

  const { totalUnderstand } = yield select(getConversationStore)

  const newTotalUnderstand = {
    totalMessagesFromBot: totalUnderstand.totalMessagesFromBot + 1,
    totalNotUnderstandMessages : messageInfo.understandingMaster === false ? totalUnderstand.totalNotUnderstandMessages + 1 : totalUnderstand.totalNotUnderstandMessages,
    totalUnderstandMessages : messageInfo.understandingMaster !== false ? totalUnderstand.totalUnderstandMessages + 1 : totalUnderstand.totalUnderstandMessages
  }

  yield put({ type: CONVERSATION_TOTAL_UNDERSTAND_SUCCESS, payload: newTotalUnderstand })
}

function* handleUpdateEntities({ payload: entities }) {
  const { topEntities } = yield select(getConversationStore)

  const newTopEntities = entities.reduce((acc, entity) => {
    const existingEntity = acc.find(e => e._id === entity.name);

    if (existingEntity) {
      existingEntity.total += 1;
    } else {
      acc.push({ _id: entity.name, total: 1 });
    }

    return acc;
  }, [...topEntities]);

  newTopEntities.sort((a, b) => b.total - a.total);

  yield put({ type: CONVERSATION_TOP_ENTITIES_SUCCESS, payload: newTopEntities })
}

function* handleUpdateIntents({ payload: intents }) {
  const { topIntents } = yield select(getConversationStore)

  const newTopIntents = intents.reduce((acc, intent) => {
    const existingIntent = acc.find(i => i._id === intent.name);

    if (existingIntent) {
      existingIntent.total += 1;
    } else {
      acc.push({ _id: intent.name, total: 1 });
    }

    return acc;
  }, [...topIntents]);

  newTopIntents.sort((a, b) => b.total - a.total, newTopIntents);

  yield put({ type: CONVERSATION_TOP_INTENTS_SUCCESS, payload: newTopIntents })
}

export function* handleReceivedNewMessageAndShouldUpdateTotalMessages() {
  yield takeLatest(RECEIVED_NEW_MESSAGE_UPDATE_TOTAL_MESSAGES, handleUpdateTotalMessages);
}

export function* handleReceivedNewMessageAndShouldUpdateTotalUnderstand() {
  yield takeLatest(RECEIVED_NEW_MESSAGE_UPDATE_TOTAL_UNDERSTAND, handleUpdateTotalUnderstand);
}

export function* handleReceivedNewEntities() {
  yield takeLatest(RECEIVED_NEW_ENTITIES, handleUpdateEntities);
}

export function* handleReceivedNewIntents() {
  yield takeLatest(RECEIVED_NEW_INTENTS, handleUpdateIntents);
}

export function* countMessagesByCompanyId() {
	yield takeLatest(CONVERSATION_TOTAL_MESSAGES, countMessagesByCompanyIdService);
}

export function* countUnderstandByCompanyId() {
	yield takeLatest(CONVERSATION_TOTAL_UNDERSTAND, countUnderstandByCompanyIdService);
}

export function* findTopIntentsByCompanyId() {
	yield takeLatest(CONVERSATION_TOP_INTENTS, findTopIntentsByCompanyIdService);
}

export function* findTopEntitiesByCompanyId() {
	yield takeLatest(CONVERSATION_TOP_ENTITIES, findTopEntitiesByCompanyIdService);
}

export function* findMessagesEvent() {
	yield takeLatest(CONVERSATION_MESSAGES, findMessages);
}

export function* findConversationsInProgressByCompanyId() {
	yield takeLatest(CONVERSATION_IN_PROGRESS, findConversationsInProgressByCompanyIdService);
}

export function* findMessagesByIdEvent() {
	yield takeLatest(Types.CONVERSATION_MESSAGES_BY_ID, findMessagesById);
}

export function* findUnderstandingMessagesByIdEvent() {
	yield takeLatest(Types.UNDERSTANDING_MESSAGES_BY_ID, findUnderstandingMessagesById);
}

export function* startInterventionSaga() {
	yield takeLatest(Types.START_INTERVENTION, startIntervention);
}

export default function* rootSaga() {
	yield all([
		fork(countMessagesByCompanyId),
		fork(countUnderstandByCompanyId),
		fork(findTopIntentsByCompanyId),
		fork(findTopEntitiesByCompanyId),
		fork(findMessagesEvent),
		fork(findMessagesByIdEvent),
		fork(findUnderstandingMessagesByIdEvent),
    fork(findConversationsInProgressByCompanyId),
    fork(startInterventionSaga),
    fork(handleReceivedNewMessageAndShouldUpdateTotalMessages),
    fork(handleReceivedNewMessageAndShouldUpdateTotalUnderstand),
    fork(handleReceivedNewEntities),
    fork(handleReceivedNewIntents)
	]);
}

import Api from './Api'
import {handleError} from '../util/ApiErrorUtil'

class Bots {
    static  list(id, params) {
        return new Promise((resolve, reject) => {

            return Api.get(`/credential`, {}, params).then(response => {
                resolve(response)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }


    static remove(payload) {
        return new Promise((resolve, reject) => {
            return Api.delete(`/credential/${payload}`).then(response => {
                resolve(response)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }

    static create(payload) {
        return new Promise((resolve, reject) => {
            return Api.post('/credential',payload).then(response => {
                resolve(response)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }

    static update(payload) {
        return new Promise((resolve, reject) => {
            return Api.put(`/credential/${payload._id}`,payload).then(response => {
                resolve(response)
            }).catch((err) => {
                reject(handleError(err))
            })
        })
    }
}

export default Bots

import React from "react";
import { Button, Form, Input, message } from "antd";
import { connect } from "react-redux";
import logoSolutis from "../../assets/images/LogoSolutisPadrao.png"


import {
  hideMessage,
  userSignIn,
  showLoader
} from "../../appRedux/reducers/Auth.js";

import IntlMessages from "util/IntlMessages";

const FormItem = Form.Item;

class SignIn extends React.Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.showLoader(true);
        this.props.userSignIn(values);
      }
    });
  };

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser.companyId !== undefined) {
      this.props.history.push('/');
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { showMessage, loader, alertMessage } = this.props;

    return (

      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-login-content">
              <img className="logo-image" src={logoSolutis} alt='Neature' />
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0" id="loginForm">

                <FormItem>
                {getFieldDecorator('username', { 
                      rules: [{
                        required: true, message: 'Preciso de um nome de usuário válido'
                      }],
                    })(<Input placeholder="Nome de usuário"/>)}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Por favor digite a senha!' }],
                  })(
                    <Input type="password" placeholder="Password" />
                  )}
                </FormItem>
                <FormItem>
                  <Button loading={loader} id="purple-button" type="primary" className="gx-mb-0 gx-btn" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                </FormItem>
              </Form>
            </div>
            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showLoader
})(WrappedNormalLoginForm);

import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import FeedbackService from "../../services/Feedback"
import { Types, listAllSuccess, setIsLoadingFeedbacks } from '../reducers/Feedbacks'

import { errorMessage } from "../actions/FeedbackMessage"

const listAllRequest = async (payload) =>
    await FeedbackService.listAll(payload)
        .then(feedbacks => feedbacks)
        .catch(error => { throw error })

function* listAll({ payload }) {
    try {
        yield put(setIsLoadingFeedbacks(true))
        const feedbacks = yield call(listAllRequest, payload)
        yield put(listAllSuccess(feedbacks))
    } catch (error) {
        yield put(errorMessage(error))
    } finally {
        yield put(setIsLoadingFeedbacks(false))
    }
}

export function* listAllSaga() {
    yield takeLatest(Types.LIST_ALL, listAll)
}

export default function* rootSaga() {
    yield all([
        fork(listAllSaga)
    ]);
}

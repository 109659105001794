import Api from './Api'
import { handleError } from '../util/ApiErrorUtil'

class Inactivity {

  static list() {
    return new Promise((resolve, reject) => {
      return Api.get('/inactivity-config')
        .then(inactivities => {
          resolve(inactivities)
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  }

  static listById(id) {
    return new Promise((resolve, reject) => {
      return Api.get(`/inactivity-config/${id}`)
        .then(inactivity => {
          resolve(inactivity)
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  }

  static create(inactivity) {
    return new Promise((resolve, reject) => {
      return Api.post('/inactivity-config', inactivity)
        .then(inactivity => {
          resolve(inactivity)
        })
        .catch((err) => {
          reject(handleError(err, "Ocorreu um erro ao criar a configuração de inatividade"))
        })
    })
  }

  static update(inactivity) {
    // Verifique se a propriedade '_id' está presente
    if (!inactivity || !inactivity._id) {
        return Promise.reject('ID da inatividade não foi fornecido');
    }

    return new Promise((resolve, reject) => {
        return Api.put(`/inactivity-config/${inactivity.companyId}`, inactivity)
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(handleError(err, "Ocorreu um erro ao atualizar a configuração de inatividade"));
            });
    });
}


  static remove(id) {
    return new Promise((resolve, reject) => {
      return Api.delete(`/inactivity-config/${id}`)
        .then(inactivity => {
          resolve(inactivity)
        })
        .catch((err) => {
          reject(handleError(err, "Ocorreu um erro ao deletar a configuração de inatividade"))
        })
    })
  }

  static wordCloudByInactivity() {
    return new Promise((resolve, reject) => {
      return fetch('https://cloud-word.chatbot.solutis.com.br/word/sec')
        .then(response => response.blob())
        .then(blob => {
          resolve(URL.createObjectURL(blob))
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  }
}

export default Inactivity

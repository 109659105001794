import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import FilesService from "../../services/Files"
import { Types, listSuccess,  } from '../reducers/FilesAgent'

import { errorMessage } from "../actions/FeedbackMessage"
 
// const getCompanyStore = state => state.company;

const listRequest = async (id) =>
    await FilesService.list(id)
        .then(files => files)
        .catch(error => { throw error })

const trainRequest = async (payload) =>
            await FilesService.train(payload)
                .then(train => train)
                .catch(error => { throw error })
        
// const updateDocuments = async (id) =>
//             await FilesService.updateDocuments(id)
//                 .then(files => files)
//                 .catch(error => { throw error })
        
const removeRequest = async (name) =>
    await FilesService.remove(name)
        .then(status => status)
        .catch(error => { throw error })

function* list({payload}) {
    try {
        const files = yield call(listRequest,payload)
        yield put(listSuccess(files))
    } catch (error) {
        yield put(errorMessage(error))
    }
}

function* addFile({payload}) {
    try {
        console.log("e assim que chamar um reduzr para uma ctions", payload)
        //  const files = yield call(updateDocuments,payload)

         // yield put(listSuccess(files))
    } catch (error) {
        yield put(errorMessage(error))
    }
}

function* remove({payload}) {
    try {
         yield call(removeRequest,payload)
         const files = yield call(listRequest,payload.agent)
        console.log("ADD FILE PRA CARALHO",files)
        yield put(listSuccess(files))
        
    } catch (error) {
        yield put(errorMessage(error))
    }
}

function* trainAgentfnc({payload}) {
    try {
         yield call(trainRequest,payload)        
    } catch (error) {
        yield put(errorMessage(error))
    }
}
export function* listSaga() {
    yield takeLatest(Types.LIST, list)
}

export function* addFileSaga() {
    yield takeLatest(Types.ADD_FILE, addFile)
}
export function* removeSaga() {
    yield takeLatest(Types.REMOVE, remove)
}

export function* trainAgent() {
    yield takeLatest(Types.TRAIN_AGENT, trainAgentfnc)
}
export default function* rootSaga() {
    yield all([
        fork(listSaga),
        fork(removeSaga),
        fork(addFileSaga),
        fork(trainAgent)
    ]);
}

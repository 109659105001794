import React, {Component} from "react";
import {connect} from "react-redux";
import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch} from "react-router-dom";
import {ConfigProvider} from "antd";
import {IntlProvider} from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../../pages/Signin";
import SignUp from "../SignUp";
import {setInitUrl,validateToken} from "../../appRedux/reducers/Auth";
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "appRedux/actions/Setting";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
} from "../../constants/ThemeSetting";

const BOXED_LAYOUT = 'boxed-layout'
const FULL_LAYOUT = 'full-layout'
const FRAMED_LAYOUT = 'framed-layout'

const RestrictedRoute = ({component: Component, authUser, refresh, ...rest}) =>
  <Route
    {...rest}
    render={props =>{
      if(authUser.companyId){
        return <Component {...props} />
      }else{
        const token = localStorage.getItem("token")
        if(token){
          refresh()
        }
        return <Redirect
          to={{pathname: '/signin', state: {from: props.location}}}
        />
      }
    }}
  />

class App extends Component {

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove(BOXED_LAYOUT);
      document.body.classList.remove(FRAMED_LAYOUT);
      document.body.classList.add(FULL_LAYOUT);
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove(FULL_LAYOUT);
      document.body.classList.remove(FRAMED_LAYOUT);
      document.body.classList.add(BOXED_LAYOUT);
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove(BOXED_LAYOUT);
      document.body.classList.remove(FULL_LAYOUT);
      document.body.classList.add(FRAMED_LAYOUT);
    }
  };

  setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  componentDidMount() {
  
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);

    if (params.has("theme")) {
      this.props.setThemeType(params.get('theme'));
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get('nav-style'));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get('layout-type'));
    }
  }

  render() {
    const {match, location, layoutType, navStyle, locale, authUser, initURL, company} = this.props;

    if (location.pathname === '/') {
      if (authUser.companyId === undefined) {
        return ( <Redirect to={'/signin'}/> );
      }else if(company && company.stepTutorial < 4){
        return ( <Redirect to={'/orquestra'}/> );
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        return ( <Redirect to={'/analytics'}/> );
      } else {
        return ( <Redirect to={initURL}/> );
      }
    }

    this.setLayoutType(layoutType);
    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <ConfigProvider locale={currentAppLocale.antd}>

        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>

          <Switch>
            <Route exact path='/signin' component={SignIn}/>
            <Route exact path='/signup' component={SignUp}/>
            <RestrictedRoute path={`${match.url}`} authUser={authUser} component={MainApp} refresh={this.props.validateToken}/>
          </Switch>

        </IntlProvider>

      </ConfigProvider>
    )
  }
}

const mapStateToProps = ({settings, auth, company}) => {
  const {locale, navStyle, themeType, layoutType} = settings;
  const {authUser, initURL} = auth;
  return {
    locale,
    navStyle,
    themeType,
    layoutType,
    authUser,
    initURL,
    company: company.company
  }
};
export default connect(mapStateToProps, {
  validateToken, setInitUrl, setThemeType, onNavStyleChange, onLayoutTypeChange
})(App);

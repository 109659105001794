import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";

import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";

import { THEME_TYPE_LITE } from "../../constants/ThemeSetting";

import {
  SIDEBAR_ROUTE_ANALYTICS,
  SIDEBAR_ROUTE_SERVICE_MONITORING,
  SIDEBAR_ROUTE_CLIENTS,
  SIDEBAR_ROUTE_CALENDAR,
  SIDEBAR_ROUTE_CHANNELS,
  SIDEBAR_ROUTE_BOTS,
  SIDEBAR_ROUTE_ACTIONS,
  SIDEBAR_ROUTE_TEMPLATES,
  SIDEBAR_ROUTE_FILES,
  SIDEBAR_ROUTE_INTERVENTION_EVENTS,
  SIDEBAR_ROUTE_INACTIVITY,
  SIDEBAR_ROUTE_ROLES,
  SIDEBAR_ROUTE_PERMISSIONS,
  SIDEBAR_ROUTE_USERS,
  SIDEBAR_ROUTE_CONTACTS,
  SIDEBAR_ROUTE_AGENTS,
  SIDEBAR_ROUTE_CUSTOMER_SERVICE,
} from "../../constants/ActionTypes";

import IntlMessages from "../../util/IntlMessages";
import { IsAuthorized, isAuthorizedGroup } from "../../util/AuthUtil";
import CustomerServiceIcon from "../../components/CustomIcons/CustomerServiceIcon";
const MenuItemGroup = Menu.ItemGroup;

const CUSTOMER_URL = process.env.REACT_APP_CUSTOMER_URL;

class SidebarContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      token: null,
    }
  }

  componentDidMount() {
    const parsedToken = JSON.parse(localStorage.getItem("token"))
    this.setState({
      token: parsedToken
    })
  }

  render() {
    const { themeType, pathname, authUser } = this.props;
    const selectedKeys = pathname.substr(1).includes("/") ? pathname.split("/")[1] : pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[1];
    
    return (
      <Auxiliary>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <p></p>
        <Menu
        defaultOpenKeys={[defaultOpenKeys]}
        selectedKeys={[selectedKeys]}
        theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
        mode="inline"
        >
        {isAuthorizedGroup("DASHBOARD", authUser.permissions) && (
          <MenuItemGroup
          key="dashboard"
          className="gx-menu-group"
          title={<IntlMessages id="sidebar.dashboard" />}
          >
          {IsAuthorized(SIDEBAR_ROUTE_ANALYTICS, 'READ', authUser.permissions) && (
            <Menu.Item key="analytics">
            <Link to="/analytics">
              <Icon type="dashboard" />
              <IntlMessages id="sidebar.analytics" />
            </Link>
            </Menu.Item>
          )}

          {IsAuthorized(SIDEBAR_ROUTE_SERVICE_MONITORING, 'READ', authUser.permissions) && (
            <Menu.Item key="service-monitoring">
            <Link to="/service-monitoring">
              <Icon type="monitor" />
              <IntlMessages id="sidebar.dashboard.service" />
            </Link>
            </Menu.Item>
          )}
          </MenuItemGroup>
        )}
        {isAuthorizedGroup("CUSTOMER_SERVICE", authUser.permissions) && (
          <MenuItemGroup
          key="general-settings"
          className="gx-menu-group"
          title={<IntlMessages id="sidebar.servicePanel" />}
          >
            {IsAuthorized(SIDEBAR_ROUTE_CUSTOMER_SERVICE, 'READ', authUser.permissions) && (
              <Menu.Item key="external-link">
              <a style={{display: 'flex', alignItems: 'center'}} href={`${CUSTOMER_URL}/external-access?access_token=${this.state.token}`} target="_blank" rel="noopener noreferrer">
              <CustomerServiceIcon/>
              <IntlMessages id="sidebar.externalLink" />
              </a>
            </Menu.Item>
            )}
        </MenuItemGroup>
        )}
        {isAuthorizedGroup("CUSTOMER_SETTINGS", authUser.permissions) && (
          <MenuItemGroup
          key="customer-settings-service"
          className="gx-menu-group"
          title={<IntlMessages id="sidebar.customerServiceSettings" />}
          >
          {IsAuthorized(SIDEBAR_ROUTE_CLIENTS, 'READ', authUser.permissions) && (
            <Menu.Item key="companies">
            <Link to="/companies">
              <Icon type="bank" />
              <IntlMessages id="sidebar.companies" />
            </Link>
            </Menu.Item>
          )}

          {IsAuthorized(SIDEBAR_ROUTE_CALENDAR, 'READ', authUser.permissions) && (
            <Menu.Item key="calendars">
            <Link to="/calendars">
              <Icon type="calendar" />
              <IntlMessages id="sidebar.calendar" />
            </Link>
            </Menu.Item>
          )}
          {IsAuthorized(SIDEBAR_ROUTE_CHANNELS, 'READ', authUser.permissions) && (
            <Menu.Item key="channel">
            <Link to="/channel">
              <Icon id="channelButton" type="mobile" />
              <IntlMessages id="sidebar.channel" />
            </Link>
            </Menu.Item>
          )}
          {IsAuthorized(SIDEBAR_ROUTE_BOTS, 'READ', authUser.permissions) && (
            <Menu.Item key="bots">
            <Link to="/bots">
              <Icon id="botsButton" type="robot" />
              <IntlMessages id="sidebar.bots" />
            </Link>
            </Menu.Item>
          )}
          {IsAuthorized(SIDEBAR_ROUTE_ACTIONS, 'READ', authUser.permissions) && (
            <Menu.Item key="actions">
            <Link to="/actions">
              <Icon id="actionsButton" type="api" />
              <IntlMessages id="sidebar.actions" />
            </Link>
            </Menu.Item>
          )}

          {IsAuthorized(SIDEBAR_ROUTE_TEMPLATES, 'READ', authUser.permissions) && (
            <Menu.Item key="template">
            <Link to="/template">
              <Icon type="ordered-list" />
              <IntlMessages id="sidebar.template" />
            </Link>
            </Menu.Item>
          )}
          {IsAuthorized(SIDEBAR_ROUTE_FILES, 'READ', authUser.permissions) && (
            <Menu.Item key="files">
            <Link to="/files">
              <Icon type="cloud-upload" />
              <IntlMessages id="sidebar.files" />
            </Link>
            </Menu.Item>
          )}
          {IsAuthorized(SIDEBAR_ROUTE_INTERVENTION_EVENTS, 'READ', authUser.permissions) && (
            <Menu.Item key="intervention-events">
            <Link to="/intervention-events">
              <Icon id="interventionEventsButton" type="bell" />
              <IntlMessages id="sidebar.interventionEvents" />
            </Link>
            </Menu.Item>
          )}
          {IsAuthorized(SIDEBAR_ROUTE_INACTIVITY, 'READ', authUser.permissions) && (
            <Menu.Item key="inactivity">
            <Link to="/inactivity">
              <Icon type="clock-circle" />
              <IntlMessages id="sidebar.inactivity" />
            </Link>
            </Menu.Item>
          )}
          </MenuItemGroup>
        )}
        {isAuthorizedGroup("GENERAL_SETTINGS", authUser.permissions) && (
          <MenuItemGroup
          key="general-settings"
          className="gx-menu-group"
          title={<IntlMessages id="sidebar.generalSettings" />}
          >
          {IsAuthorized(SIDEBAR_ROUTE_ROLES, 'READ', authUser.permissions) && (
            <Menu.Item key="roles">
            <Link to="/roles">
              <Icon type="control" />
              <IntlMessages id="sidebar.roles" />
            </Link>
            </Menu.Item>
          )}

          {IsAuthorized(SIDEBAR_ROUTE_PERMISSIONS, 'READ', authUser.permissions) && (
            <Menu.Item key="permission">
            <Link to="/permission">
              <Icon type="reconciliation" />
              <IntlMessages id="sidebar.permission" />
            </Link>
            </Menu.Item>
          )}
          {IsAuthorized(SIDEBAR_ROUTE_USERS, 'READ', authUser.permissions) && (
            <Menu.Item key="users">
            <Link to="/users">
              <Icon type="usergroup-add" />
              <IntlMessages id="sidebar.users" />
            </Link>
            </Menu.Item>
          )}

          {IsAuthorized(SIDEBAR_ROUTE_CONTACTS, 'READ', authUser.permissions) && (
            <Menu.Item key="contacts">
            <Link to="/contacts">
              <Icon type="phone" />
              <IntlMessages id="sidebar.contacts" />
            </Link>
            </Menu.Item>
          )}
          </MenuItemGroup>
        )}
        {isAuthorizedGroup("MANAGEMENT_AGENTS", authUser.permissions) && (
          <MenuItemGroup
          key="gestaoDeAgentes"
          className="gx-menu-group"
          title={<IntlMessages id="sidebar.managementAgents" />}
          >
          {IsAuthorized(SIDEBAR_ROUTE_AGENTS, 'READ', authUser.permissions) && (
            <Menu.Item key="agents">
            <Link to="/agents">
              <Icon type="robot" />
              <IntlMessages id="sidebar.agents" />
            </Link>
            </Menu.Item>
          )}
          </MenuItemGroup>
        )}
        </Menu>
        <p></p>
      </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  const { authUser } = auth;
  return { navStyle, themeType, locale, pathname, authUser };
};
export default connect(mapStateToProps)(SidebarContent);

import { 
  SIDEBAR_ROUTE_ACTIONS, 
  SIDEBAR_ROUTE_AGENTS, 
  SIDEBAR_ROUTE_ANALYTICS, 
  SIDEBAR_ROUTE_BOTS, 
  SIDEBAR_ROUTE_CALENDAR, 
  SIDEBAR_ROUTE_CHANNELS, 
  SIDEBAR_ROUTE_CLIENTS, 
  SIDEBAR_ROUTE_CONTACTS, 
  SIDEBAR_ROUTE_CUSTOMER_SERVICE, 
  SIDEBAR_ROUTE_FILES, 
  SIDEBAR_ROUTE_INACTIVITY, 
  SIDEBAR_ROUTE_INTERVENTION_EVENTS, 
  SIDEBAR_ROUTE_PERMISSIONS, 
  SIDEBAR_ROUTE_ROLES, 
  SIDEBAR_ROUTE_SERVICE_MONITORING, 
  SIDEBAR_ROUTE_TEMPLATES, 
  SIDEBAR_ROUTE_USERS 
} from "./ActionTypes";

export const GROUP_ROUTES_TYPES = {
  'DASHBOARD': 
  [
    SIDEBAR_ROUTE_ANALYTICS, 
    SIDEBAR_ROUTE_SERVICE_MONITORING
  ],
  'CUSTOMER_SERVICE':
  [
    SIDEBAR_ROUTE_CUSTOMER_SERVICE,
  ],
  'CUSTOMER_SETTINGS': 
  [
    SIDEBAR_ROUTE_CLIENTS, 
    SIDEBAR_ROUTE_CALENDAR, 
    SIDEBAR_ROUTE_CHANNELS, 
    SIDEBAR_ROUTE_BOTS, 
    SIDEBAR_ROUTE_ACTIONS, 
    SIDEBAR_ROUTE_TEMPLATES, 
    SIDEBAR_ROUTE_FILES,
    SIDEBAR_ROUTE_INTERVENTION_EVENTS, 
    SIDEBAR_ROUTE_INACTIVITY
  ],
  'GENERAL_SETTINGS':[
    SIDEBAR_ROUTE_ROLES,
    SIDEBAR_ROUTE_PERMISSIONS,
    SIDEBAR_ROUTE_USERS,
    SIDEBAR_ROUTE_CONTACTS
  ],
  'MANAGEMENT_AGENTS': [
    SIDEBAR_ROUTE_AGENTS
  ]
}
import { handleError } from "../util/ApiErrorUtil"
import Api from "./Api"
const BASE_SGBD = process.env.REACT_APP_SGBD_URL
class Curatorship {
  static list(payload) {
    return new Promise((resolve, reject) => {
      return Api.get(`/curadoria/${payload}`, {
        path: BASE_SGBD,
      } ).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }

  static create(payload) {
    return new Promise((resolve, reject) => {
      return Api.post('/curadoria', payload, {
        path: BASE_SGBD,
      }
      ).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }

  static update(payload) {
    return new Promise((resolve, reject) => {
      return Api.put(`/curadoria/${payload._id}`, payload, {
        path: BASE_SGBD,
      }).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }

  static remove(payload) {
    return new Promise((resolve, reject) => {
      return Api.delete(`/curadoria/${payload}`, {
        path: BASE_SGBD,
      }).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }

  static train(payload) {
    return new Promise((resolve, reject) => {
      return Api.get(`/curadoria/${payload}/train`, {
        path: BASE_SGBD,
      }).then(response => {
        resolve(response)
      }).catch((err) => {
        reject(handleError(err))
      })
    })
  }
}


export default Curatorship;
const { GROUP_ROUTES_TYPES } = require("../constants/GroupTyples")

const IsAuthorized = (route, can, allowedFor) => {
    console.log("IsAuthorized", route, can, allowedFor)
    const resource = route.replace("/","").toUpperCase()
    const permission = (allowedFor).find(permission => permission.resource === resource)
    return permission ? permission.can.find(type => type === can) : false;
}

const isAuthorizedGroup = (group, allowedFor) => {
    return GROUP_ROUTES_TYPES[group].some(route => IsAuthorized(route, 'READ', allowedFor))
}

module.exports = {
    IsAuthorized,
    isAuthorizedGroup
}
